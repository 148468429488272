import {useAuthStore} from "~/stores/auth";
import {storeToRefs} from "pinia";

export interface MenuItem {
    heading?: string;
    sectionTitle?: string;
    route?: string;
    pages?: Array<MenuItem>;
    keenthemesIcon?: string;
    bootstrapIcon?: string;
    target?: string;
    sub?: Array<MenuItem>;
}

const store = useAuthStore();
const {currentUser} = storeToRefs(store);
import {USER_STATUS_AVAILABLE} from "~/types/interfaces/entitites/UserInterface";

let MainMenuConfig: Array<MenuItem> = [];

if (currentUser.value.status !== USER_STATUS_AVAILABLE['draft']) {
    MainMenuConfig = [
            {
                pages: [
                    {
                        heading: 'Novedades',
                        route: '/novedades',
                        keenthemesIcon: 'element-11',
                        bootstrapIcon: 'bi-megaphone',
                        target: '_self',
                    },
                    {
                        heading: 'Mi perfil',
                        route: '/mi-perfil',
                        keenthemesIcon: 'element-11',
                        bootstrapIcon: 'bi-person-vcard',
                        target: '_self',
                    }
                ]
            },
            {
                heading: "Gestión Sentencias",
                pages: [
                    {
                        heading: 'Sentencias',
                        route: '/sentencias/procedimientos/listado',
                        keenthemesIcon: 'element-11',
                        bootstrapIcon: 'bi-file-earmark-medical',
                        target: '_self',
                    },
                    {
                        heading: 'Reclamar',
                        route: 'https://asufin.asufin.com/es/iniciar-mi-reclamacion',
                        // route: '/sentencias/reclamar',
                        keenthemesIcon: 'element-11',
                        bootstrapIcon: 'bi-briefcase',
                        target: '_blank',
                    },
                    {
                        heading: 'Descargas',
                        route: '/descargas/listado',
                        keenthemesIcon: 'element-11',
                        bootstrapIcon: 'bi-cloud-download',
                        target: '_self',
                    },
                ],
            },
            {
                heading: "Soporte",
                pages: [
                    // {
                    //     heading: 'Tickets',
                    //     route: '/soporte/tickets/listado',
                    //     keenthemesIcon: 'element-11',
                    //     bootstrapIcon: 'bi-chat-square-text'
                    // },
                    {
                        heading: 'FAQS',
                        route: '/soporte/preguntas-frecuentes/listado',
                        keenthemesIcon: 'element-11',
                        bootstrapIcon: 'bi-question-circle',
                        target: '_self',
                    },
                ]
            },
            {
                heading: "Calculadoras",
                pages: [
                    {
                        heading: 'Calculadoras',
                        route: 'https://www.asufin.com/calculadora-asufin/',
                        keenthemesIcon: 'element-11',
                        bootstrapIcon: 'bi-calculator',
                        target: '_blank',
                    },
                ]
            },
        ]
    ;
}


export default MainMenuConfig;
