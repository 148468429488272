<template>
  <!--begin::Help drawer-->
  <div
    id="kt_help"
    class="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="help"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'350px', 'md': '525px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#send_contact_toggle"
    data-kt-drawer-close="#send_contact_close"
  >
    <!--begin::Card-->
    <div class="card shadow-none rounded-0 w-100">
      <!--begin::Header-->
      <div class="card-header" id="send_contact_header">
        <h3 class="card-title fw-bold text-dark">
          Contáctanos
        </h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon explore-btn-dismiss me-n5"
            id="send_contact_close"
          >
            <i class="bi bi-x-circle fs-1"></i>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <VForm
          class="form w-100"
          id="kt_login_signin_form"
          :validation-schema="formValidation"
          @submit="onSubmitForm"
      >
        <!--begin::Body-->
        <div class="card-body" id="send_contact_body">
          <!--begin::Content-->
          <div
              id="send_contact_scroll"
              class="hover-scroll-overlay-y"
              data-kt-scroll="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-wrappers="#send_contact_body"
              data-kt-scroll-dependencies="#send_contact_header, #send_contact_drawer_footer"
              data-kt-scroll-offset="5px"
          >
            <!--begin::Input group-->
            <div class="row fv-row mb-3">
              <div class="col-12">
                <label class="form-label mb-3 required">Asunto</label>
                <!--begin::Input-->
                <Field
                    tabindex="1"
                    class="form-control"
                    type="text"
                    name="subject"
                    autocomplete="off"
                    placeholder="Describe tu problema"
                    aria-label="Describe tu problema"
                ></Field>

                <!--end::Input-->
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="subject"/>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Input group-->
            <!--begin::Input group-->
            <div class="row fv-row mb-3">
              <div class="col-12">
                <label class="form-label mb-3 required">Cuéntanos tu problema</label>
                <!--begin::Input-->
                <Field
                    tabindex="1"
                    class="form-control"
                    type="text"
                    as="textarea"
                    name="message"
                    autocomplete="off"
                    placeholder="Describe tu problema"
                    aria-label="Describe tu problema"
                ></Field>

                <!--end::Input-->
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="message"/>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Input group-->
          </div>
          <!--end::Content-->
        </div>
        <!--end::Body-->

        <!--begin::Footer-->
        <div class="card-footer py-5 text-center" id="send_contact_drawer_footer">
          <!--begin::Button-->
          <button
              type="reset"
              ref="resetButton"
              id="send_contact_reset"
              class="btn btn-light me-3"
          >
            Descartar
          </button>
          <!--end::Button-->

          <!--begin::Submit button-->
          <button
              tabindex="5"
              type="submit"
              ref="submitButton"
              id="send_contact_form"
              class="btn btn-primary"
          >
            <span class="indicator-label"> Enviar </span>
            <span class="indicator-progress">
                Espera, por favor...
              <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
          <!--end::Submit button-->
        </div>
        <!--end::Footer-->
      </VForm>
    </div>
    <!--end::Card-->
  </div>
  <!--end::Help drawer-->
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import * as Yup from "yup";
import {DrawerComponent} from "assets/theme/metronic/ts/components";
import {useContact} from "~/composables/useContact";
import {ErrorMessage, Field, Form as VForm} from "vee-validate";

export default defineComponent( {
  name: "ThemeDrawerHelp",
  components: {
    VForm, Field, ErrorMessage
  },
  setup() {
    const submitButton = ref<HTMLButtonElement | null>(null);
    const resetButton = ref<HTMLButtonElement | null>(null);

    const formValidation = Yup.object({
      subject: Yup.string().required('Introduce el motivo del mensaje'),
      message: Yup.string().required('Describe en qué te podemos ayudar'),
    });

    const onSubmitForm = async (values: any) => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      await useContact().sendContact(values);

      resetButton.value.click();
      DrawerComponent.reinitialization();

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      submitButton,
      resetButton,
      formValidation,
      onSubmitForm
    };
  },
});
</script>
