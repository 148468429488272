<template>
  <div
    class="engage-toolbar d-flex position-fixed px-5 fw-bold zindex-2 top-50 end-0 transform-90 mt-20 gap-2"
  >
    <!--begin::Help drawer toggle-->
    <button
      id="send_contact_toggle"
      class="engage-help-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm px-5 rounded-top-0"
    >
      Ayuda
    </button>
    <!--end::Help drawer toggle-->
  </div>
  <LHelpDrawer />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LHelpDrawer from "~/components/theme/metronic/layout/drawers/HelpDrawer.vue";

export default defineComponent({
  name: "ThemeToolButtons",
  components: {LHelpDrawer},
});
</script>
