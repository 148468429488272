<template>
  <!--begin::Footer-->
  <div id="kt_app_sidebar_footer" class="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6">

  </div>
  <!--end::Footer-->
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { getAssetPath } from '@/assets/theme/metronic/ts/helpers/assets';

export default defineComponent({
  name: 'ThemeSidebarFooter',
  components: {},
  setup() {
    return {
      getAssetPath
    };
  }
});
</script>
